var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createCodeVerifier, generateCodeChallenge } from './pkce';
import { HTTPError, createGetTokenBody, getMobileDeviceOS, twoFactorHeaders, addSecondsToDate } from './utils';
import { CookieStorage, LocalStorage } from './storage';
import { EncodeType } from './types/utils';
const REFRESH_TOKEN_COOKIE_AGE = 90; // 90 days. This value matches SLAS cartridge.
class Auth {
    constructor(api) {
        this._api = api;
        this._config = Object.assign(Object.assign({}, api._config), { auth: Object.assign({ slasCallbackEndpoint: '/callback', usidStorageKey: 'usid', encUserIdStorageKey: 'enc-user-id', tokenStorageKey: 'token', refreshTokenStorageKey: 'refresh-token', refreshTokenGuestStorageKey: 'refresh-token-guest', oidStorageKey: 'oid' }, api._config.auth) });
        this._onClient = typeof window !== 'undefined';
        this._cookieStorage = this._onClient ? new CookieStorage() : new Map();
        this._localStorage = this._onClient ? new LocalStorage() : new Map();
        this._customerId = undefined;
        this._oid = this._onClient ? this._cookieStorage.get(this._config.auth.oidStorageKey) : undefined;
        const configOid = api._config.organizationId;
        this._oid = this._cookieStorage.get(this._config.auth.oidStorageKey) || configOid;
        if (this._oid !== configOid) {
            this._clearAuth();
            this._saveOid(configOid);
        }
        else {
            this._authToken = this._onClient
                ? this._cookieStorage.get(this._config.auth.tokenStorageKey)
                : undefined;
            this._refreshToken = this._onClient
                ? this._cookieStorage.get(this._config.auth.refreshTokenStorageKey) ||
                    this._cookieStorage.get(this._config.auth.refreshTokenGuestStorageKey)
                : undefined;
            this._isTSOB = this._onClient
                ? this.getTSOB()
                : false;
            this._usid = this._onClient ? this._cookieStorage.get(this._config.auth.usidStorageKey) : undefined;
            this._encUserId = this._onClient
                ? this._cookieStorage.get(this._config.auth.encUserIdStorageKey)
                : undefined;
        }
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.createOCAPISession = this.createOCAPISession.bind(this);
    }
    /**
     * Checks if user is on trusted agent path and looks for required login params
     * and sets the access token cookie for use on SFCC
     * @returns boolean true if trusted agent login is to be attempted
     */
    getTSOB() {
        const isTSOBUrl = window.location.pathname === '/tsob';
        if (isTSOBUrl) {
            const queryParams = new URLSearchParams(window.location.search);
            const paramsObject = Object.fromEntries(queryParams.entries());
            const requiredParams = ['access_token', 'id_token', 'expires_in', 'usid', 'customer_id'];
            const hasAllParams = requiredParams.every(param => param in paramsObject && paramsObject[param].length > 0);
            if (hasAllParams) {
                this._TSOBToken = {
                    access_token: paramsObject.access_token,
                    id_token: paramsObject.id_token,
                    expires_in: paramsObject.expires_in,
                    usid: paramsObject.usid,
                    customer_id: paramsObject.customer_id
                };
                this._localStorage.set('TSOB', "true");
                return true;
            }
        }
        return false;
    }
    get pendingLogin() {
        return this._pendingLogin;
    }
    get authToken() {
        return this._cookieStorage.get(this._config.auth.tokenStorageKey) || this._authToken;
    }
    get oid() {
        return this._cookieStorage.get(this._config.auth.oidStorageKey);
    }
    get refreshToken() {
        return (this._cookieStorage.get(this._config.auth.refreshTokenStorageKey) ||
            this._cookieStorage.get(this._config.auth.refreshTokenGuestStorageKey));
    }
    get usid() {
        return this._cookieStorage.get(this._config.auth.usidStorageKey);
    }
    get encUserId() {
        return this._cookieStorage.get(this._encUserId);
    }
    /** Stores the given oid token. */
    _saveOid(oid) {
        this._oid = oid;
        if (this._onClient) {
            this._cookieStorage.set(this._config.auth.oidStorageKey, oid);
        }
    }
    createOCAPISession() {
        return this._api.shopperSessions.createSession({});
    }
    login(credentials) {
        return __awaiter(this, void 0, void 0, function* () {
            // Calling login while its already pending will return a reference
            // to the existing promise.
            if (this._pendingLogin) {
                return this._pendingLogin;
            }
            let retries = 0;
            const startLoginFlow = () => __awaiter(this, void 0, void 0, function* () {
                let authorizationMethod;
                if (this._isTSOB) {
                    authorizationMethod = this._trustedSystemLogin();
                }
                else if (credentials) {
                    authorizationMethod = this._loginWithCredentials(credentials);
                }
                else if (this._refreshToken) {
                    authorizationMethod = this._refreshAccessToken();
                }
                else {
                    // Detect we're an Android or IOS device
                    const isApp = getMobileDeviceOS();
                    if (isApp) {
                        authorizationMethod = this._loginAsGuest();
                    }
                    else {
                        authorizationMethod = this._refreshAccessToken();
                    }
                }
                return authorizationMethod.catch((error) => {
                    if (retries === 0 &&
                        (error.message === 'EXPIRED_TOKEN' || error.message === 'invalid refresh_token')) {
                        retries = 1; // we only retry once
                        this._clearAuth();
                        return startLoginFlow();
                    }
                    throw error;
                });
            });
            this._pendingLogin = startLoginFlow().finally(() => {
                // When the promise is resolved, we need to remove the reference so
                // that subsequent calls to `login` can proceed.
                this._pendingLogin = undefined;
            });
            return this._pendingLogin;
        });
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            const body = {
                refresh_token: this.refreshToken,
            };
            const response = yield fetch(`${this._config.origin}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-Logout`, {
                method: 'POST',
                body: JSON.stringify(body),
            });
            const logoutResponse = yield response.json();
            if (logoutResponse.customer_id) {
                yield this._clearAuth();
                // Refresh access tokens
                yield this._handleShopperLoginTokenResponse(logoutResponse);
                const customer = {
                    customerId: logoutResponse.customer_id,
                    authType: 'guest',
                    usid: logoutResponse.usid,
                    encUserId: logoutResponse.enc_user_id,
                    basket: logoutResponse.basket || {},
                    c_customerGroups: logoutResponse.c_customerGroups || []
                };
                return customer;
            }
        });
    }
    /**
     * Register a new customer.
     * @param body The form data from registration
     * @param attachAtrophy Should the atrophy headers be attached for 2FA. Due to imports the 2FA custom preference
     *  cannot be checked here. So this needs to be passed though from the caller.
     * @returns
     */
    registerCustomer(body, attachAtrophy) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = attachAtrophy ? yield twoFactorHeaders() : {};
            const request = yield fetch(`${this._config.origin}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-Register`, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: headers
            });
            const response = yield request.json();
            if (!request.ok) {
                throw new Error(JSON.stringify(response));
            }
            return response;
        });
    }
    ocapiAPILogin() {
        return __awaiter(this, void 0, void 0, function* () {
            const codeVerifier = yield createCodeVerifier();
            const codeChallenge = yield generateCodeChallenge(codeVerifier, EncodeType.BASE64);
            if (this._onClient) {
                sessionStorage.setItem('codeVerifier', codeVerifier);
            }
            const authOptions = {
                headers: {
                    Authorization: '',
                    'Content-Type': `application/x-www-form-urlencoded`,
                },
                parameters: {
                    redirect_uri: `${this._config.origin}${this._config.auth.slasCallbackEndpoint}`,
                    client_id: this._config.clientId,
                    code_challenge: codeChallenge,
                    response_type: 'code',
                    hint: 'guest',
                },
            };
            const authResponse = yield this._api.shopperLogin.authorizeCustomer(authOptions, true);
            if (authResponse.status >= 400) {
                const json = yield authResponse.json();
                throw new HTTPError(`${authResponse.status}`, json.message);
            }
            const { grantType, code, usid, redirectUri } = createGetTokenBody(authResponse.url, `${this._config.origin}${this._config.auth.slasCallbackEndpoint}`, this._onClient ? window.sessionStorage.getItem('codeVerifier') : codeVerifier);
            const body = {
                code: code,
                grant_type: grantType,
                usid: usid,
                code_verifier: codeVerifier,
                client_id: this._config.clientId,
                redirect_uri: redirectUri,
                channel_id: this._config.siteId,
            };
            const options = {
                headers: {
                    'Content-Type': `application/x-www-form-urlencoded`,
                },
                body,
            };
            const response = yield this._api.shopperLogin.getAccessToken(options);
            // Check for error response before handling the token
            if (response.status_code) {
                throw new HTTPError(response.status_code, response.message);
            }
            this._handleShopperLoginTokenResponse(response);
            return response;
        });
    }
    // Update the State with Tokens and encoded user id when received for later re-use
    _handleShopperLoginTokenResponse(tokenResponse) {
        const { access_token, customer_id, usid, enc_user_id } = tokenResponse;
        this._customerId = customer_id;
        this._saveAccessToken(`Bearer ${access_token}`);
        this._saveUsid(usid);
        if ((enc_user_id === null || enc_user_id === void 0 ? void 0 : enc_user_id.length) > 0) {
            this._saveEncUserId(enc_user_id);
        }
        if (this._onClient) {
            sessionStorage.removeItem('codeVerifier');
        }
    }
    _loginAsGuest() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.ocapiAPILogin();
            // Save access token and encUserId from shopper login for use in the BCA
            this._saveRefreshToken(response.refresh_token, 'guest');
            // A guest customerId will never return a customer from the customer endpoint
            const customer = {
                authType: 'guest',
                customerId: response.customer_id,
                usid: response.usid,
            };
            return customer;
        });
    }
    _getLoggedInBasket() {
        return __awaiter(this, void 0, void 0, function* () {
            const device = getMobileDeviceOS();
            if (device !== 'unknown') {
                const response = yield fetch(`${this._config.origin}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Customer-GetLoggedInBasket`, {
                    method: 'GET',
                });
                const json = yield (response === null || response === void 0 ? void 0 : response.json());
                return json;
            }
            return null;
        });
    }
    // Send update to the BC App if we're in the App so it can maintain session bridge
    _handleBCAppLogin(tokenResponse) {
        var _a, _b, _c, _d;
        // Get the SLAS data
        const { access_token, refresh_token, refresh_token_expires_in, customer_id, usid, enc_user_id, id_token, expires_in } = tokenResponse;
        const authData = {
            access_token,
            refresh_token,
            refresh_token_expires_in,
            customer_id,
            usid,
            enc_user_id,
            id_token,
            expires_in,
        };
        // Detect we're an Android or IOS device
        const device = getMobileDeviceOS();
        try {
            switch (device) {
                case 'Android':
                    // Send the data to the App using the app interface if exists
                    // @ts-ignore
                    (_a = window.appInterface) === null || _a === void 0 ? void 0 : _a.success(JSON.stringify(authData));
                    break;
                case 'iOS':
                    // @ts-ignore
                    (_d = (_c = (_b = window === null || window === void 0 ? void 0 : window.webkit) === null || _b === void 0 ? void 0 : _b.messageHandlers) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.postMessage(authData);
                    break;
            }
        }
        catch (e) {
            // Not in App, ignore
        }
    }
    // Credential Login uses a Custom SFCC endpoint wrapping SLAS rather than direct SLAS calls for flexibility and security
    _loginWithCredentials(credentials) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            // Put the creds into a formData object for submission to Login endpoint
            const formData = new FormData();
            formData.append('loginEmail', credentials === null || credentials === void 0 ? void 0 : credentials.email);
            formData.append('loginPassword', credentials === null || credentials === void 0 ? void 0 : credentials.password);
            const codes = Object.keys(credentials).filter(key => key.startsWith('code_'));
            if (codes.length === 6) {
                codes.forEach((codeName) => {
                    formData.append(codeName, credentials[codeName]);
                });
            }
            // Call the SFCC Custom Login endpoint
            const response = yield fetch(`${this._config.origin}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-Login`, {
                method: 'POST',
                body: formData,
                headers: yield twoFactorHeaders()
            });
            // Get the Response Body
            const loginResponse = yield (response === null || response === void 0 ? void 0 : response.json());
            // Handle Non-Success responses - skip for suspension process as we need data to be passed back to handle this process
            if (response.status >= 400) {
                if ('suspended' in loginResponse && loginResponse.suspended) {
                    //Account is suspended, update Verification Data in Store which should trigger verification process
                    return {
                        suspended: true,
                        authType: 'guest',
                        verificationToken: loginResponse.verificationToken
                    };
                }
                else {
                    const message = (_b = (_a = loginResponse.message) === null || _a === void 0 ? void 0 : _a.replace(response.status.toString(), '')) === null || _b === void 0 ? void 0 : _b.trim();
                    throw new HTTPError(`${response.status}`, message);
                }
            }
            //Check for 2FA
            if ('twoFactorRequired' in loginResponse && loginResponse.twoFactorRequired) {
                return {
                    twoFactorRequired: true,
                    twoFactorFailed: loginResponse.twoFactorFailed,
                    hint: loginResponse.hint,
                    authType: 'guest',
                };
            }
            //Other responses are guarded by status check above.
            const successResponse = loginResponse;
            // Successful Login, setup all the tokens etc
            this._handleShopperLoginTokenResponse(successResponse);
            // For BC App, notify the App with the login tokens
            this._handleBCAppLogin(successResponse);
            // Return the customer Info so we can trigger getting the full customer data
            const customer = {
                customerId: successResponse.customer_id,
                authType: 'registered',
                usid: successResponse.usid,
                encUserId: successResponse.enc_user_id,
                basket: successResponse.basket,
                incompleteOffers: successResponse === null || successResponse === void 0 ? void 0 : successResponse.incompleteOffers,
                access_token_expiry: addSecondsToDate(new Date(), successResponse === null || successResponse === void 0 ? void 0 : successResponse.expires_in),
                c_customerGroups: successResponse.c_customerGroups
            };
            return customer;
        });
    }
    /**
     * Establish session and SCAPI login for trusted agents
     *
     * @returns preliminary customer object
     */
    _trustedSystemLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            const TSOBlogin = this._TSOBToken;
            const response = yield fetch(`${this._config.origin}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-TrustedSystemLogin`, {
                method: 'POST',
                headers: Object.assign(Object.assign({}, yield twoFactorHeaders()), { 'Content-Type': 'application/json' }),
                body: JSON.stringify({
                    access_token: TSOBlogin.access_token,
                    customer_id: TSOBlogin.customer_id,
                })
            });
            // Get the Response Body
            const parsedResponse = yield (response === null || response === void 0 ? void 0 : response.json());
            TSOBlogin.c_customerGroups = parsedResponse.c_customerGroups;
            if (TSOBlogin.customer_id) {
                // Check for error response before handling the token
                if (TSOBlogin.status_code) {
                    throw new HTTPError(TSOBlogin.status_code, TSOBlogin.message);
                }
                this._handleShopperLoginTokenResponse(TSOBlogin);
                // Return the customer Info so we can trigger getting the full customer data
                const customer = {
                    customerId: TSOBlogin.customer_id,
                    authType: 'guest',
                    usid: TSOBlogin.usid,
                    c_customerGroups: TSOBlogin.c_customerGroups || [],
                    basket: {},
                    incompleteOffers: {},
                    access_token_expiry: addSecondsToDate(new Date(), TSOBlogin.expires_in)
                };
                if (TSOBlogin.id_token.length > 0) {
                    customer.authType = 'registered';
                }
                let loggedInBasket = {};
                // If no basket but customer is logged in reinitialize customer basket in backend
                if (customer.authType === 'registered') {
                    const basketRecovery = yield this._getLoggedInBasket();
                    loggedInBasket = basketRecovery.basket || {};
                    customer.c_customerGroups = basketRecovery.c_customerGroups || [];
                }
                customer.basket = loggedInBasket || {};
                return customer;
            }
        });
    }
    /**
     * Refreshes Logged In Token
     */
    _refreshAccessToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`${this._config.origin}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-GetAccessToken`, {
                method: 'POST',
                headers: yield twoFactorHeaders(),
                body: `{}`,
            });
            // Get the Response Body
            const loginResponse = yield (response === null || response === void 0 ? void 0 : response.json());
            if (loginResponse.customer_id) {
                // Check for error response before handling the token
                if (loginResponse.status_code) {
                    throw new HTTPError(loginResponse.status_code, loginResponse.message);
                }
                this._handleShopperLoginTokenResponse(loginResponse);
                // For BC App, notify the App with the login tokens
                this._handleBCAppLogin(loginResponse);
                // Return the customer Info so we can trigger getting the full customer data
                const customer = {
                    customerId: loginResponse.customer_id,
                    authType: 'guest',
                    usid: loginResponse.usid,
                    encUserId: loginResponse.enc_user_id,
                    c_customerGroups: loginResponse.c_customerGroups || [],
                    basket: {},
                    incompleteOffers: {},
                    access_token_expiry: addSecondsToDate(new Date(), loginResponse.expires_in)
                };
                if (!loginResponse.isGuest) {
                    customer.authType = 'registered';
                }
                else {
                    this._saveRefreshToken(loginResponse.refresh_token, 'guest');
                }
                let loggedInBasket = loginResponse.basket;
                // If no basket but customer is logged in reinitialize customer basket in backend
                if (!loggedInBasket && customer.authType === 'registered') {
                    const basketRecovery = yield this._getLoggedInBasket();
                    loggedInBasket = basketRecovery.basket || {};
                    customer.c_customerGroups = basketRecovery.c_customerGroups || [];
                }
                customer.basket = loggedInBasket || {};
                //incomplete offers
                if (loginResponse.incompleteOffers) {
                    customer.incompleteOffers = loginResponse.incompleteOffers;
                }
                return customer;
            }
        });
    }
    /**
     * Stores the given auth token.
     */
    _saveAccessToken(token) {
        this._authToken = token;
        if (this._onClient) {
            this._cookieStorage.set(this._config.auth.tokenStorageKey, token);
        }
    }
    /**
     * Stores the given usid token.
     */
    _saveUsid(usid) {
        this._usid = usid;
        if (this._onClient) {
            this._cookieStorage.set(this._config.auth.usidStorageKey, usid);
        }
    }
    /**
     * Stores the given enc_user_id token. enc = encoded
     */
    _saveEncUserId(encUserId) {
        this._encUserId = encUserId;
        if (this._onClient) {
            this._cookieStorage.set(this._config.auth.encUserIdStorageKey, encUserId);
        }
    }
    /**
     * Removes the stored auth token.
     */
    _clearAuth() {
        this._customerId = undefined;
        this._authToken = undefined;
        this._refreshToken = undefined;
        this._usid = undefined;
        this._encUserId = undefined;
        if (this._onClient) {
            this._cookieStorage.delete(this._config.auth.tokenStorageKey);
            this._cookieStorage.delete(this._config.auth.refreshTokenStorageKey);
            this._cookieStorage.delete(this._config.auth.usidStorageKey);
            this._cookieStorage.delete(this._config.auth.encUserIdStorageKey);
        }
    }
    /**
     * Stores the given refresh token.
     */
    _saveRefreshToken(refreshToken, type) {
        if (type === 'registered') {
            this._cookieStorage.set(this._config.auth.refreshTokenStorageKey, refreshToken, {
                expires: REFRESH_TOKEN_COOKIE_AGE,
            });
            this._cookieStorage.delete(this._config.auth.refreshTokenGuestStorageKey);
            return;
        }
        this._cookieStorage.set(this._config.auth.refreshTokenGuestStorageKey, refreshToken, {
            expires: REFRESH_TOKEN_COOKIE_AGE,
        });
        this._cookieStorage.delete(this._config.auth.refreshTokenStorageKey);
    }
}
export default Auth;
