var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { nanoid } from 'nanoid';
import { encode as base64encode } from 'base64-arraybuffer';
import { EncodeType } from './types/utils';
// Globals
const isServer = typeof window === 'undefined';
/**
 * Creates Code Verifier use for PKCE auth flow.
 */
export const createCodeVerifier = () => __awaiter(void 0, void 0, void 0, function* () {
    if (isServer) {
        //@ts-ignore
        const randomstring = yield import('randomstring').then((module) => {
            return module.default.generate(128);
        });
        return randomstring;
    }
    return nanoid(128);
});
const hexEncode = (data) => {
    const hashArray = new Uint8Array(data);
    return Array.from(hashArray, byte => byte.toString(16).padStart(2, '0')).join('');
};
/**
 * Creates Code Challenge based on Code Verifier
 */
export const generateCodeChallenge = (codeVerifier, encodeType) => __awaiter(void 0, void 0, void 0, function* () {
    let encodedValue;
    if (isServer) {
        yield import('crypto').then((module) => {
            //@ts-ignore
            const crypto = module.default;
            encodedValue = crypto.createHash('sha256').update(codeVerifier).digest(encodeType);
        });
    }
    else {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const digest = yield window.crypto.subtle.digest('SHA-256', data);
        switch (encodeType) {
            case EncodeType.HEX:
                encodedValue = hexEncode(digest);
                break;
            case EncodeType.BASE64:
            default:
                encodedValue = base64encode(digest);
                break;
        }
    }
    if (encodeType === EncodeType.BASE64) {
        encodedValue = encodedValue.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }
    return encodedValue;
});
