export var HttpMethods;
(function (HttpMethods) {
    HttpMethods["POST"] = "POST";
    HttpMethods["PUT"] = "PUT";
    HttpMethods["PATCH"] = "PATCH";
    HttpMethods["GET"] = "GET";
    HttpMethods["DELETE"] = "DELETE";
})(HttpMethods || (HttpMethods = {}));
export var EncodeType;
(function (EncodeType) {
    EncodeType["BASE64"] = "base64";
    EncodeType["HEX"] = "hex";
})(EncodeType || (EncodeType = {}));
